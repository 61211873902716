.CustomTag {
  border-radius: 3px;
  backdrop-filter: blur(17px);
  height: 25px;
  padding: 4px 10px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.12px;
  text-align: right;
  white-space: nowrap;
}
.CustomTag__deletable {
  padding-right: 4px;
}
.CustomTag__deletebutton {
  border: 0;
  margin: 0;
  padding: 3px;
  background: transparent;
}
.CustomTag__deletebutton:active,
.CustomTag__deletebutton:focus {
  border: 0;
  outline: 0;
}
.CustomTag.CustomTag__color_yellow {
  background-color: rgba(255, 204, 0, 0.2);
  color: #FF9500;
}
.CustomTag.CustomTag__color_blue {
  background-color: rgba(0, 122, 255, 0.2);
  color: #007AFF;
}
.CustomTag.CustomTag__color_green {
  background-color: rgba(0, 180, 110, 0.2);
  color: #00B46E;
}
